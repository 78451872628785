import React, {useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import HalfFareTravelcardPlus from "./HalfFareTravelcardPlus";
import HalfFareTravelcard from "./HalfFareTravelcard";
import GATravelcard from "./GATravelcard";
import NoCard from "./NoCard";
import CostHistogram from "./CostHistogram";
import TravelHabitsForm from "./TravelHabitsForm";
import {BinData} from "./types";
import CostTable from "./CostTable";
import CostDetails from "./CostDetails";
import {SbbTitle} from "@sbb-esta/lyne-react/title";

const WelcomeMessage: React.FC = () => {
    const {t} = useTranslation();
    const [binData, setBinData] = useState<BinData[]>([]);
    const [selectedBin, setSelectedBin] = useState<BinData | null>(null);  // State for the selected bin
    const costDetailsRef = useRef<HTMLDivElement>(null);  // Ref for the CostDetails component

    // Function to handle selecting a bin and scrolling to CostDetails
    const handleSetSelectedBin = (bin: BinData) => {
        setSelectedBin(bin);  // Always expects a valid BinData object, not null

        // Scroll to the CostDetails component
        if (costDetailsRef.current) {
            costDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div>
            <SbbTitle level="1">{t('welcome.title')}</SbbTitle>
            <p>{t('welcome.problemStatement')}</p>
            <div className="sbb-grid">
                <div className="sbb-col sbb-col--6 sbb-spacing-responsive-xs">
                    <NoCard/>
                </div>
                <div className="sbb-col sbb-col--6 sbb-spacing-responsive-xs">
                    <HalfFareTravelcard/>
                </div>
                <div className="sbb-col sbb-col--6 sbb-spacing-responsive-xs">
                    <HalfFareTravelcardPlus/>
                </div>
                <div className="sbb-col sbb-col--6 sbb-spacing-responsive-xs">
                    <GATravelcard/>
                </div>
            </div>
            <SbbTitle level="2">{t('welcome.graphicalOverviewTitle')}</SbbTitle>
            <p>{t('welcome.graphicalOverviewIntro')}</p>
            <h3>{t('welcome.graphicalOverviewHowToUseTitle')}</h3>
            <p>{t('welcome.graphicalOverviewHowToUse')}</p>
            <TravelHabitsForm setBinData={setBinData}/>
            {binData.length > 0 && (
                <>
                    <CostHistogram bins={binData} setSelectedBin={handleSetSelectedBin} />  {/* Use handleSetSelectedBin */}
                    <CostTable bins={binData} setSelectedBin={handleSetSelectedBin} />      {/* Use handleSetSelectedBin */}
                    {selectedBin && (
                        <div ref={costDetailsRef}> {/* Ref for scrolling */}
                            <CostDetails data={selectedBin}/> {/* Render CostDetails based on selected bin */}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default WelcomeMessage;
